
  import { reactive, ref, onMounted, computed } from 'vue'
  import { useStore } from "vuex";
  import { Actions } from "@/store/enums/InvoiceLotsEnums.ts";
  import { Actions as LotActions } from "@/store/enums/LotEnums.ts";
  import {Actions as SupplierActions} from '@/store/enums/SupplierEnums.ts';
  import { useRouter,useRoute } from "vue-router";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import CreateInvoice from '@/views/lots/invoice_lots/invoice/CreateInvoice.vue'
  export default {
    name : 'ConsumerEdit',
    components : {
      CreateInvoice
    },
    setup(){

      const store = useStore();
      const router = useRouter();
      const route = useRoute();

      const success = ref(false);

      store.dispatch(Actions.GET_INVOICE_LOT,{id : route.params.id});
      store.dispatch(LotActions.GET_COMPOSITE_LOTS_ON_STOCK,{});
      store.dispatch(SupplierActions.ALL_SUPPLIERS);


      const id = route.params.id

      onMounted(() => {



        setCurrentPageBreadcrumbs("Lots",[
          {title : "Invoiced Lots List",to : "/lots/invoice-composites"},
          {title : "Edit Invoiced Lot",to : "/lots/invoice-composites/edit/"+route.params.id}
        ]);
      });

      const submitButton = ref<HTMLElement | null>(null);

      const errors = reactive({
        value : {}
      });


      const submitForm = () => {

        if(submitButton.value){
          submitButton.value.setAttribute("data-kt-indicator", "on");
        }

        store.dispatch(Actions.UPDATE_INVOICE_LOT,store.getters.currentInvoiceLot).then((r) => {
          if(r.success){
            errors.value = [];
            success.value = true;
            window.scrollTo(0,0);
            submitButton.value?.removeAttribute("data-kt-indicator");
            setTimeout(() => {
              router.push('/lots/invoice-composites')
            },1000)
          }

        }).catch((response) => {
          setTimeout(function(){
            const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
            input.focus();
          },100)

          errors.value = response.data.errors;

          submitButton.value?.removeAttribute("data-kt-indicator");

        })
      }

      const compositeLotsOnStock = computed(() => {
        const lots : string[] = [];

        store.getters.mycompositeLotsOnStock.forEach((item) => {


            if(Number(store.getters.currentInvoiceLot.lot_id) == Number(item.lot_id)){

              lots.push(item)

            }else{

              if(Number(item.inStock) > 0){

                lots.push(item)

              }

            }


        });
        return lots;
      })

      const customerSuppliers = computed(() => {
        const customers : string[] = [];

        store.getters.myAllSuppliers.forEach((item) => {

          if(Number(item.isCustomer) == 1){

            customers.push(item)

          }

        });
        return customers;
      })

      return {
        submitButton,
        submitForm,
        errors,
        store,
        success,
        compositeLotsOnStock,
        customerSuppliers,
        id
      }
    }
  }
