<template>
  <div>
  <!-- Button trigger modal -->
  <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#exampleModalInvoice">
    Invoice
  </button>
  <!-- Modal -->
  <div class="modal fade" id="exampleModalInvoice" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Create Invoice</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label fw-bold fs-6 required">Invoice #</label>
              <input type="text" class="form-control" v-model="formData.invoiceNumber" :class="{'is-invalid' : errors.value && errors.value.invoiceNumber}">
              <span class="text-danger" v-if="errors.value && errors.value.invoiceNumber">{{errors.value && errors.value.invoiceNumber[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label fw-bold fs-6 required">Order #</label>
              <input type="text" class="form-control" v-model="formData.orderNumber" :class="{'is-invalid' : errors.value && errors.value.orderNumber}">
              <span class="text-danger" v-if="errors.value && errors.value.orderNumber">{{errors.value && errors.value.orderNumber[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label fw-bold fs-6">Price</label>
              <input type="number" class="form-control" v-model="formData.price">
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label fw-bold fs-6">Terms</label>
              <input type="text" class="form-control" v-model="formData.terms">
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="close-modal-invoice">Close</button>
          <button
            type="submit"
            id="createInvoiceSubmitBtn"
            class="btn btn-primary fw-bolder"
            @click="submitForm();"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { Actions } from "@/store/enums/ReportsEnums.ts";
import { useStore } from 'vuex'
import { ref, reactive, onMounted } from 'vue'

 export default {
   name : 'CreateInvoice',
   emits: ['input'],
   props : ['id'],
   setup(props, { emit }){
     const store = useStore();

     const errors = reactive({
       value : {}
     });

     const formData = reactive({
       id : props.id,
       price : 20,
       terms : '',
       invoiceNumber : null,
       orderNumber : null
     })

     onMounted(() => {

       const myModalEl = document.getElementById('exampleModalInvoice') as any
       myModalEl.addEventListener('hidden.bs.modal', function () {
         formData.price = 20;
         formData.terms = '';
       })
     })


     const submitForm = () => {
       const submitButton = ref<HTMLElement | null>(document.getElementById('createInvoiceSubmitBtn') as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }
       console.log(formData)
       store.dispatch(Actions.CREATE_INVOICE,formData).then((r) => {

         const blob = new Blob([r], { type: 'application/pdf', filename : 'labRequestForm.pdf' } as object)

         const url = window.URL.createObjectURL(blob)

         window.open(url)

         submitButton.value?.removeAttribute("data-kt-indicator");

           setTimeout(function(){

             const closeModal = document.getElementById('close-modal-invoice') as HTMLElement;
             closeModal.click();
             emit('input');

           },1000)

       }).catch((response) => {

         errors.value = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(response.data) as any)).errors;

         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)


         submitButton.value?.removeAttribute("data-kt-indicator");

       })
     }

     return {
       errors,
       formData,
       submitForm
     }
   }
 }
</script>
