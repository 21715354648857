
import { Actions } from "@/store/enums/ReportsEnums.ts";
import { useStore } from 'vuex'
import { ref, reactive, onMounted } from 'vue'

 export default {
   name : 'CreateInvoice',
   emits: ['input'],
   props : ['id'],
   setup(props, { emit }){
     const store = useStore();

     const errors = reactive({
       value : {}
     });

     const formData = reactive({
       id : props.id,
       price : 20,
       terms : '',
       invoiceNumber : null,
       orderNumber : null
     })

     onMounted(() => {

       const myModalEl = document.getElementById('exampleModalInvoice') as any
       myModalEl.addEventListener('hidden.bs.modal', function () {
         formData.price = 20;
         formData.terms = '';
       })
     })


     const submitForm = () => {
       const submitButton = ref<HTMLElement | null>(document.getElementById('createInvoiceSubmitBtn') as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }
       console.log(formData)
       store.dispatch(Actions.CREATE_INVOICE,formData).then((r) => {

         const blob = new Blob([r], { type: 'application/pdf', filename : 'labRequestForm.pdf' } as object)

         const url = window.URL.createObjectURL(blob)

         window.open(url)

         submitButton.value?.removeAttribute("data-kt-indicator");

           setTimeout(function(){

             const closeModal = document.getElementById('close-modal-invoice') as HTMLElement;
             closeModal.click();
             emit('input');

           },1000)

       }).catch((response) => {

         errors.value = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(response.data) as any)).errors;

         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)


         submitButton.value?.removeAttribute("data-kt-indicator");

       })
     }

     return {
       errors,
       formData,
       submitForm
     }
   }
 }
